import { MergeObjects } from './../core/library/objects';
import MediaSources from './media_sources';
import * as Projects from './projects';
import {parseUrlParams} from "../core/library/url";
import PushRegions from './regions';

const PROJECT = process.env.PROJECT || "";
const isLocal = window.location.hostname.indexOf('localhost') + 1 > 0;

let Config = {
    LOCALE: "en",
    PROJECT_PREFIX: "test",
    RESIZER: false,
    // project config
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: isLocal ? `http://localhost:15001/api/backend` : `/api/backend`,
    },

    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `https://auth.rt.com`,
        // current project domain
        PROJECT_DOMAIN: isLocal ? `localhost:3000` : 'planner.b37m.ru',
        // current project url path
        PROJECT_PATH: isLocal ? `http://localhost:3000` : 'https://planner.b37m.ru',
        // path for oauth api
        API: `https://auth.rt.com/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://auth.rt.com/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },
    // default date format
    DATE_FORMAT: "DD-MM-YYYY HH:mm",
    // configuration for slug plugin
    SLUG: {
        delimiter: '-',
        prefix: ''
    },

    // configuration for media
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdni.rbth.com/rbthmedia/images",
            VIDEO: "https://cdni.rbth.com/rbthmedia/video"
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },

    DOCUMENTS: {
        STATUS: ["published", "unpublished", "prepublished_status"],
        MOVE_TYPES: ['News', 'Article', "Meaning", "Embargo", 'Receipt'],
    },
    EDITOR: {
        KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
        SETTINGS: {
            language: 'en',
        },
    },
    MAPS: {
        KEY: "e70f3b03-0677-45b8-911f-830daf383e37",
    },
    ELASTIC: {
        API: isLocal ? "http://localhost:15001/api/backend/elastic" : "/api/backend/elastic"
    },
    MEDIA_DATABASE_TYPES: ['Image', 'Video', 'ExternalVideo', 'Code'],
    MEDIA_AVAILABLE_TYPES: '.jpg,.gif,.png,.jpeg,.mp4,.mp3',
    MEDIA_AVAILABLE_SOURCES: ['youtube', 'vimeo', 'facebook'],
    WIDGETS: {
        TYPES: ['Book', 'Banner', 'Trends'],
    },
    REGIONS: PushRegions,
    PHOTO_AUTHORS: [],
    SPECIAL_PROJECT_TYPES: ['Фотовыставка', 'Спец. репортаж', 'Выставка', 'Специальный материал'],
    CROPPER: {
        WIDTH: 1200,
        HEIGHT: 675,
    }
};

Config = MergeObjects(Config, Projects['shop']);
window.APP_CONFIG = {
    PROJECT: Config.PROJECT,
    MEDIA: Config.MEDIA,
};

export const GetImagePath = (args, size = '320') => {
    let files = args.files || args;


    if (!files || !files['original'] || !files['original'].path) {
        return '/images/nocover.png';
    }
    let path = files['original']['path'].split('/');
    // if (Config.MEDIA.CDN.IMAGES.indexOf('resizer') + 1 > 0 || Config.MEDIA.CDN.IMAGES.indexOf('cdnimages') + 1 > 0) {
    //     path[path.length - 1] = `${size}_${path[path.length - 1]}`;
    // }
    return `${Config.MEDIA.CDN.IMAGES}/${path.join('/')}`;
    // return `https://cdni.vm.ru/${path.join('/')}`;
}

export const CheckUserPermissions = (permission_name = '') => {
    if (!window.user) return false;
    // if (window.user.is_admin || permission_name === '') {
    //     return true;
    // }
    return window.user.permissions.indexOf(permission_name.toUpperCase()) + 1 > 0;
}

export const WSConnection = 'ws://127.0.0.1:15001/api/backend/ws'

export const WSActions = {
    'online': 'online-users',
};

export default Config;


